<!--
 * @Description: 采购商订单详情
 * @Author: zhang zhen
 * @Date: 2023-05-16 17:14:00
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-18 23:58:16
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/orderDetailsForUser.vue
-->
<template>
  <div>
    <div class="ForUserAndPurchaseDetails info-container-box">
      <div class="ForUserAndPurchaseDetails-title">{{ orderTitle }}</div>
      <div class="ForUserAndPurchaseDetails-subtitle">
        <div class="header-options">
          <span class="label">订单编号：{{ orderNo }}</span>
          <a class="copy" @click="handleCopy(orderNo)">复制</a>
        </div>
        <div class="header-options">
          <span class="label">关联需求单编号：{{ purchaseNo }}</span>
          <a class="copy" @click="handleCopy(purchaseNo)">复制</a>
        </div>
        <a-button ghost size="small" style="margin-left: 15px;color: #FF6026;border-color: #FF6026;font-size: 12px;" icon="message" @click="handleChat">咨询TA</a-button>
        <div class="restPart" v-if="cancelOrder">
          <a-button type="primary" icon="close-circle" @click="showRemoveOrder = true">取消订单</a-button>
        </div>
      </div>
      <a-tabs v-model="activeTab" @change="handleChangeTabs">
        <a-tab-pane key="4" tab="详情">
          <RequirementInfo ref="RequirementInfoRef" :needSteps="true" :showOrderInfo="false" :purchaseId="purchaseId" :needLoadNow="true" :needMoreInfo="false" @updateOrderInfo="handleEmitUpdate" />
        </a-tab-pane>
        <a-tab-pane key="1" tab="概览">
          <SupplierInfo ref="SupplierInfo" :orderNo="orderNo" :needEdit="false" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="单据">
          <BillUploader ref="BillUploader" :orderNo="orderNo" :orderStatus="orderStatus" @ok="handleLoadInfo" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="合同">
          <ContractPart ref="ContractPart" :orderNo="orderNo" :orderStatus="orderStatus" fileKey="contractFileCustomer" />
        </a-tab-pane>
        <a-tab-pane key="7" tab="评价">
          <AppraisalInfo :orderNo="orderNo" />
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- <InterestedCard /> -->
    <a-modal title="温馨提示" :visible="showRemoveOrder" centered @ok="handleRemoveOrder" @cancel="showRemoveOrder = false">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>您确定取消订单吗?</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import InterestedCard from '@/components/tools/interestedCard.vue'
import BasicInfo from './children/basicInfo.vue'
import UploadBills from './children/uploadBills.vue'
import ContractPart from './children/contractPart.vue'
import FacilitatorBills from './children/facilitatorBills.vue'
import UploadProgress from './children/UploadProgress.vue'
import SupplierInfo from './children/supplierInfoForUser.vue'
import SupplierUploadProgress from './children/supplierUploadProgress.vue'
import BillUploader from './children/billUploader.vue'
import { getAction, postAction, putAction } from '@/api/manage'
import InfoDetails from './children/infoDetails.vue'
import AppraisalInfo from './children/appraisalInfo.vue'
import RequirementInfo from '@/components/plugins/requirementOrderInfo.vue'

export default {
  name: 'orderDetailsForUser',
  components: {
    InterestedCard,
    RequirementInfo,
    BasicInfo,
    UploadBills,
    ContractPart,
    FacilitatorBills,
    UploadProgress,
    SupplierInfo,
    SupplierUploadProgress,
    BillUploader,
    InfoDetails,
    AppraisalInfo
  },
  data() {
    return {
      showRemoveOrder: false,
      activeTab: '4',
      orderNo: '',
      orderTitle: '',
      infoType: '3',
      orderStatus: null,
      purchaseId: null,
      hasEdit: false,
      purchaseNo: null,
      cancelOrder: false,
    }
  },
  created() {
    const { orderNo, orderTitle, hasEdit, purchaseId } = this.$route.query
    orderTitle && (this.orderTitle = orderTitle)
    orderNo && (this.orderNo = orderNo)
    // this.handleChangeTabs('4')
    this.purchaseId = purchaseId
    !orderTitle && this.handleLoadInfo()
    hasEdit && (this.hasEdit = hasEdit == true)
  },
  methods: {
    handleChat() {
      this.$refs.RequirementInfoRef.handleEmitChat();
    },
    /* 复制单号 */
    handleCopy(code) {
      const _input = document.createElement('input') // 直接构建input
      _input.value = code // 设置内容
      document.body.appendChild(_input) // 添加临时实例
      _input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(_input) // 删除临时实例
      this.$message.success('复制成功')
    },

    handleLoadInfo() {
      getAction('/order/detail', {
        orderNo: this.orderNo,
        userType: 1
      }).then(res => {
        const { success, data, message } = res
        if (success) {
          this.orderTitle = data.orderTitle
        }
      })
    },
    handleChangeTabs(e) {
      this.$nextTick(_ => {
        if (e == '1') {
          this.$refs.SupplierInfo.handleLoadInfo()
        } else if (e == '2') {
          this.$refs.BillUploader.handleLoadTransportInfo(this.orderNo)
        } else if (e == '3') {
          this.$refs.ContractPart.handleLoadInfo()
        }
      })
    },
    handleSwitchNav(url) {
      this.$router.push(url)
    },
    /* 确认按钮 */
    handleOK() {
      // 0-待签署，1-已签署，2-已付款，3-生产中，4-已发货，5-运输中，6-已收货，7-已完结，-1已取消
      postAction('/order/transported', {
        orderNo: this.orderNo
      }).then(res => {
        const { success, message, data } = res
        if (success) {
          // this.$message.success(message)
          this.handleLoadInfo()
        } else {
          this.$message.warning(message)
        }
      })
      this.handleChangeTabs(this.activeTab)
    },
    /* 取消订单 */
    handleRemoveOrder() {
      putAction('/order/remove', {
        orderNo: this.orderNo
      }).then(res => {
        const { success, message } = res
        if (success) {
          this.$router.go(-1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleEmitUpdate({ purchaseNo, cancelOrder }) {
      this.purchaseNo = purchaseNo;
      this.cancelOrder = cancelOrder;
    },
  }
}
</script>

<style lang="less" scoped>
.ForUserAndPurchaseDetails {
  min-height: 580px;
  ::v-deep.ant-tabs {
    color: rgba(0, 0, 0, 0.85);
  }
  &-title {
    color: #090b12;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  &-subtitle {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    grid-gap: 0 30px;
    .copy {
      font-size: 13px;
      margin-left: 8px;
      font-weight: 500;
    }
  }

  .restPart {
    text-align: right;
    flex: 1 0 0;
    min-width: 0;
  }
}
::v-deep .ant-tabs-tab-active {
  font-weight: 500;
}

.btns {
  text-align: center;
}
@import '~@/styles/detail.less';
.info-card {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}
</style>
